.dictionary-page {
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(233, 233, 233);
  ul {
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    flex-direction: column;

    .word {
      width: 500px;
      margin-bottom: 20px;
      list-style-type: none;
      background-color: #f5e7e4;

      box-shadow: 0 0 5px 1px rgba(0,0,0,.3);
      border-radius: 3px;
      padding: 20px;

      .buttons {
        display: flex;
        justify-content: flex-end;
        transition: .5s;

        .failure {
          color: red;
        }
        .success {
          color: green;
        }
        button {
          width: 125px;
          border: none;
          border-radius: 3px;
          font-size: .8em;
          background-color: #B8B8AA;
          transition: .2s;
          color: rgba(0,0,0,.7);
          outline: none;
          padding: 4px 0;
          margin-left: 10px;
        }
        button:hover, button:focus {
          background-color: #586F6B;
          box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
          color: rgba(0,0,0,.9);
        }
      }
    }
    @media only screen and (max-width: 991px) {
      .word {
        width: 90%;
        box-sizing: border-box;
        .buttons {
          margin-top: 10px;
        }
      }
    }
  }
}
