@import '../../common/styles/spacing';
@import 'appbar';

.app-bar {
  background-color: rgb(92, 92, 92);
  height: $appbar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left, .right {
    display: flex;

    a {
      text-decoration: none;
      color: black;
      font-size: 30px;
    }

    .greeting {
      margin-right: 20px;  
    }
    margin: 0 10px;
  }
}
