.entry {
  .kanji {
    margin-bottom: 4px;
    border-bottom: 1px solid rgba(0,0,0,.3);
    span {
      height: 2em;
      margin-right: 2px;
    }
    .main {
      font-size: 2em;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    margin-bottom: 10px;

    .sense-container {
      .dictionary-data {
        margin-top: 5px;
        .pos {
          display: inline-block;
          font-size: .8em;
          color: rgba(0,0,0,.6);
          margin-bottom: 5px;
        }
        li {
          margin-bottom: 3px;
        }
      }
  
      .actions {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        .sentence-input {
          width: 70%;
          background-color: #f5e7e4;
          border: 1px solid #a5a5a5;
          border-radius: 3px;
        }
      }
    }
  }

  .sentences-container {
    .sentence {
      margin: 10px 0;

      .translation {
        margin-top: 5px;
        color: #927f7f;
      }
    }
  }
}
