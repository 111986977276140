.card-session-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.flashcard {
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  flex-direction: column;

  .sentence-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .sentence {
      color: rgb(145, 145, 145);
      letter-spacing: .1em;
    }
    .surface-form {
      color: rgb(34, 34, 34);
      font-size: 40px;
      margin: 0 4px;
    }
  }

  .response-container {
    margin-top: 80px;
    display: flex;
    gap: 5px;
  }
}
