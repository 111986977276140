.auth-page {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;

  background-color: #c0ccc4;

  form {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.3);
    border-radius: 3px;
    padding: 20px;

    background-color: #f5e7e4;
    label {
      display: flex;
      flex-direction: column;
      span {
        color: #585858;
        font-size: 1em;
      }
    }

    input {
      border: none;
      border-radius: 3px;
      outline: none;
      transition: .2s;
      width: 250px;
      padding: 5px 10px;
      font-size: 1.5em;
      margin-bottom: 10px;
      margin-top: 4px;
    }
    input:hover, input:focus {
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: .6em;
      button {
        width: 125px;
        border: none;
        border-radius: 3px;
        font-size: 1.2em;
        height: calc(10px + 1.5em);
        background-color: #e0e0af;
        transition: .2s;
        color: #585858;
        outline: none;
      }
      button:hover, button:focus {
        background-color: #586F6B;
        box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
        color: rgba(0,0,0,.9);
      }

      .signup:hover, button:focus {
        background-color: #C42021;
      }
    }
  }
}
