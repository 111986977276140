.home-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: sans-serif;
  background-color: rgb(233, 233, 233);

  header {
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px;
    box-sizing: border-box;
    background-color: #deb0a6;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.4);

    span {
      margin-right: 10px;
      font-size: calc(1em + 10px);
      color: rgba(0,0,0,.7);
    }
    button {
      border: none;
      border-radius: 3px;
      font-size: 1em;
      background-color: #586F6B;
      transition: .2s;
      color: rgba(0,0,0,.9);
      outline: none;
      padding: 5px;
    }
    button:hover, button:focus {
      background-color: #586F6B;
      box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
      color: rgba(0,0,0,.9);
    }
  }

  form {
    margin-top: 100px;
    display: flex;

    .dictionary-search-input {
      border: none;
      border-radius: 3px;
      outline: none;
      transition: .2s;
      width: 600px;
      padding: 5px 5px;
      font-size: 1.5em;
    }

    .dictionary-search-submit {
      width: 125px;
      border: none;
      border-radius: 3px;
      font-size: 1.2em;
      height: calc(10px + 1.5em);
      background-color: #B8B8AA;
      transition: .2s;
      color: rgba(0,0,0,.7);
      outline: none;
      margin-left: 20px;
      cursor: pointer;
    }
    button:hover, button:focus {
      background-color: #586F6B;
      box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
      color: rgba(0,0,0,.9);
    }
  }

  .card-button {
    margin-top: 20px;
    width: 125px;
    border: none;
    border-radius: 3px;
    font-size: 1.2em;
    height: calc(10px + 1.5em);
    background-color: #B8B8AA;
    transition: .2s;
    color: rgba(0,0,0,.7);
    outline: none;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .card-button:hover, .card-button:focus {
    background-color: #586F6B;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
    color: rgba(0,0,0,.9);
  }

  @media only screen and (max-width: 991px) {
    form {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      input {
        width: 90%;
        margin-bottom: 20px;
      }
    }
  }
}
